import React from "react"
import PropTypes from "prop-types"

// see: https://dev.to/bravemaster619/simplest-way-to-embed-a-youtube-video-in-your-react-app-3bk2
// see: https://kevinsimper.medium.com/full-width-youtube-embed-with-react-js-responsive-embed-509de7e7c3bf

const YouTube = ({ videoId, title }) => (
  <div
    style={{
      overflow: "hidden",
      position: "relative",
      paddingBottom: "56.25%" /* 16:9 */,
      // paddingTop: 25,
      height: 0,
    }}
  >
    <iframe
      // width="853"
      // height="480"
      src={`https://www.youtube.com/embed/${videoId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title={title}
      style={{
        left: 0,
        top: 0,
        height: "100%",
        width: "100%",
        position: "absolute",
      }}
    />
  </div>
)

YouTube.defaultProps = {
  title: `Embedded youtube`,
}

YouTube.propTypes = {
  videoId: PropTypes.string.isRequired,
  title: PropTypes.string,
}

export default YouTube
