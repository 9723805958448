// see: https://www.gatsbyjs.com/docs/mdx/programmatically-creating-pages/
import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"
import Vimeo from "@u-wave/react-vimeo"
import YouTube from "./youtube"
import Image from "gatsby-image"
import tw, { styled } from "twin.macro"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "./layout"
import Aside, { Center } from "./aside"
import Main from "./main"
// import styled from "styled-components"

const shortcodes = {
  Link,
  Vimeo,
  Main,
  Aside,
  Center,
  YouTube,
  getImage,
  GatsbyImage,
} // Provide common components here

const BannerImage = tw.img(Image)`
  w-full h-33v min-h-60
`

// const BannerImage = styled(Image)(() => [
//   `
//   ${tw`h-full w-full object-cover h-33v min-h-60`}

//   img {
//     ${tw`object-bottom`}
//   }
// `,
// ])

// see: https://www.gatsbyjs.com/blog/mdx-embedded-gatsby-image/

export default function PageTemplate({
  data: {
    mdx: {
      frontmatter: {
        client,
        project,
        date,
        thumb,
        logo,
        banner,
        bannerPosition,
        embeddedImagesLocal,
      },
      body,
    },
  },
}) {
  return (
    <Layout showNav={false}>
      {banner && (
        <BannerImage
          fluid={banner.childImageSharp.fluid}
          alt="Banner Image"
          // objectPosition={bannerPosition || "bottom center"}
          imgStyle={{
            objectPosition: bannerPosition || "bottom center",
          }}
        />
      )}
      {/* {mdx.frontmatter.logo && (
        <Image
          fixed={mdx.frontmatter.logo.childImageSharp.fixed}
          alt="Logo"
          // objectPosition="bottom center"
        />
      )} */}
      {/* <Aside logo={mdx.frontmatter.logo} /> */}
      <div className="container prose mt-8">
        <div className="flex items-center flex-col sm:flex-row">
          {logo && (
            <>
              <GatsbyImage
                image={getImage(logo)}
                alt="Logo"
                className="mr-5 mb-10 sm:mb-0"
                // objectFit="contain"
                style={{ maxHeight: "100px", maxWidth: 200 }}
                imgStyle={{ objectFit: "contain" }}
              />
              {/* <div style={{ maxHeight: "100%", height: "45px" }}>
                <GatsbyImage
                  style={{ maxHeight: "100%" }}
                  imgStyle={{ objectFit: "contain" }}
                  // fluid={data.placeholderImage.childImageSharp.fluid}
                  image={getImage(logo)}
                />
              </div> */}
              {/* <img src={getImage(logo)} alt="A dog smiling in a party hat" /> */}
            </>
          )}
          <h1 style={{ marginTop: 0, marginBottom: 0 }}>{project}</h1>
        </div>
        <div className="flex flex-row flex-wrap">
          <MDXProvider components={shortcodes}>
            {/* <MDXRenderer frontmatter={frontmatter} logo={logo}> */}
            <MDXRenderer logo={logo} localImages={embeddedImagesLocal}>
              {body}
            </MDXRenderer>
          </MDXProvider>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PortfolioItemQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        client
        project
        thumb {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        logo {
          childImageSharp {
            gatsbyImageData
          }
        }
        bannerPosition
        banner {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
