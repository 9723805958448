import React from "react"
import PropTypes from "prop-types"
import Image from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import tw, { styled } from "twin.macro"

export const Center = tw.div`
  text-center
`

const Aside = ({ children }) => (
  <div id="site-info" className="w-full sm:w-1/3 sm:pr-8">
    {children}
  </div>
)

export default Aside
